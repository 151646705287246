<style scoped>
    .radial-menu {
        position: absolute;
    }
</style>

<template>
    <div class="radial-menu" :style="{ width: (radius * 2) + 'px', height: (radius * 2) + 'px' }">
        <radial-menu-item v-for="item in items" :key="item.id" :id="item.id" :radius="radius" :centerRadius="centerRadius" :size="size" :angleOffset="angle" :angle="itemAngles[item.id]" :originAngle="(originAngle - angle)" :icon="item.icon" :offset="offset" :order="order"></radial-menu-item>
    </div>
</template>

<script>
import radialMenuItem from "./radial.menu.item.vue";

export default {
    props: {
        radius: { type: Number},
        centerRadius: { type: Number },
        angle: { type: Number },
        originAngle: { type: Number },
        offset: { type: Number },
        items: { type: Array },
        spacing: { type: Number },
        order: { type: Number },
        type: {},
        size: {},
    },
    data: function () {
        return {
        }
    },
    mounted: async function () {
    },
    created: function () {
    },
    computed: {
        itemAngles() {
            var itemCount = this.items.length;
            var midpoint = (itemCount - 1) / 2;
            var angleMap = {};
            for (var i = 0; i < itemCount; i++) {
                var item = this.items[i];
                if (this.type === "midpoint") {
                    angleMap[item.id] = (midpoint - i) * this.spacing;
                }
                else if (this.type === "left") {
                    angleMap[item.id] = i * this.spacing;
                }
                else {
                    angleMap[item.id] = -i * this.spacing;
                }
            }
            return angleMap;
        }
    },
    methods: {
    },
    components: {
        radialMenuItem
    }
}
</script>