<style lang="scss">
    @import "~vuetify/src/styles/styles.sass";

    .app-header {
        background-color: #333333 !important;
    }

    .app-logo {
        margin-top: 6px;
    }
</style>

<template>
    <v-app-bar app tile :height="height" style="overflow:hidden;" elevation="1" class="app-header">
        <router-link to="/" class="logo-container">
            <img src="../../images/emblem.png" class="app-logo" height="40" />
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar-title style="color:white;">{{ $route.meta ? $route.meta.title : appName }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="ma-0 pa-0 d-flex d-md-none" @click="openDrawer">
            <v-icon color="white">mdi-menu</v-icon>
        </v-btn>      
    </v-app-bar>
</template>

<script>
import config from "../app.config";
import events from "../app.events";

export default { 
    data: function () {
        return {
            height: config.header.height + "px",
            appName: config.app.name
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        openDrawer() {
            this.$bus.emit(events.APP_DRAWER_OPEN, { show: true });
        },
    },
    components: {
    }
}
</script>