export default {
    light: {
        primary: "#ff66ff",
        secondary: "#00dbde",
        accent: "#939caa",
        error: "#ff5B29",
        success: "#009966",
        anchor: "#00dbde"
    },
    dark: {
        primary: "#ff66ff",
        secondary: "#00dbde",
        accent: "#939caa",
        error: "#ff5B29",
        success: "#009966",
        anchor: "#00dbde"
    }
}