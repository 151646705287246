import Vue from "vue";
import { vuetify } from "@/plugins";

import "./mixins/mixins";
import "./styles/colours.css";

import router from "./routing/router";
import app from "./app/app.vue";
//import app from "./comingsoon.vue";

import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import VueTelInputVuetify from "vue-tel-input-vuetify";
Vue.use(VueTelInputVuetify, {
    vuetify,
  });

Vue.config.productionTip = false

new Vue({
    data: {
        authToken: "",
        user: {}
    },
    router,
    vuetify,
    render: h => h(app)
}).$mount('#app')
