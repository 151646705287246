import { fabric } from "fabric";
import constants from "../app/app.constants";

var forEachObjectRecursive = function (base, propertyName, callback, context, includeRoot) {
    if (includeRoot) {
        callback.call(context, base);
    }

    if (!Object.prototype.hasOwnProperty.call(base, propertyName)) { return; }

    var objects = base[propertyName];
    for (var i = 0, length = objects.length; i < length; i++) {
        var object = objects[i];
        var returned = callback.call(context, objects[i]);
        if (returned) {
            objects[i] = returned;
        }
        if (Object.prototype.hasOwnProperty.call(object, propertyName)) {
            forEachObjectRecursive(object, propertyName, callback, context, false);
        }
    }
};

// utility modifications
fabric.Object.prototype.toObject = (function (toObject) {
    return function (propertiesToInclude) {
        propertiesToInclude = (propertiesToInclude || []).concat(constants.ATTRIBUTE_WHITELIST);
        return toObject.apply(this, [propertiesToInclude]);
    };
})(fabric.Object.prototype.toObject);

Array.prototype.push.apply(fabric.Object.prototype.stateProperties, constants.ATTRIBUTE_WHITELIST);

fabric.Group.prototype.forEachObjectRecursive = function (callback, context, base) {
    if (!base) {
        base = this;
    }
    var objects = base.getObjects();
    for (var i = 0, length = objects.length; i < length; i++) {
        var object = objects[i];
        callback.call(context, objects[i], i, objects);
        if (Object.prototype.hasOwnProperty.call(object, "_objects")) {
            forEachObjectRecursive(callback, context, object);
        }
    }
    return this;
};

fabric.Object.prototype.transparentCorners = true;
fabric.Object.prototype.cornerStyle = "circle";
fabric.Object.prototype.cornerSize = 20;

export default null;