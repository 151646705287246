<style scoped>
    .record-container {
        position: relative;
        display: flex;
        justify-content: center;
    }
</style>

<template>
    <v-container class="record-container pt-8">
        <div style="text-align:center;">
            <person-avatar size="150" :avatar="$user.avatar" :name="$user.name" @click="photo"></person-avatar><br/>
            <v-btn depressed class="mt-4" @click="photo" :disabled="loading">Change Photo</v-btn>
            <div class="ma-4">
                Logged in as {{ $user.email }}
            </div>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field outlined v-model="$user.name" persistent-hint hint="Please enter your full name" :counter="100" :rules="nameRules" label="Name *" required></v-text-field>
            </v-form>
            <v-btn depressed class="mt-0" @click="save" color="primary" :loading="loading">Save Details</v-btn>
        </div>
    </v-container> 
</template>

<script>
import events from "../../app/app.events";
import personAvatar from '../person/person.avatar.vue';

export default { 
    data() {
        return {
            tab: 0,
            loading: false,
            valid: true,
            nameRules: [
                v => !!v || "Name is required",
                v => (v && v.length <= 100) || "Name must be less than 100 characters",
            ]
        }
    },
    async mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        getImage() {
            return this.$asset.getUrl(this.$user.avatar, "medium");
        },
        photo() {
            this.$bus.emit(events.APP_BOTTOM_SHEET_OPEN, { 
                show: true, title: "Select Photo", 
                button:{ title: "edit", action: () => {}, show: false }, 
                config: {
                    file: "domains/photo/photo.image", 
                    props: { 
                        ratio: 1, 
                        save: true, 
                        round: true, 
                        onCancel: this.onPhotoCancel.bind(this), 
                        onDone: this.onPhotoDone.bind(this), 
                        link: { ref: "user", refId: this.$user.id, field: "avatar" } 
                    }
                }
            });
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.loading = true;
            await this.$api.post("user/update", this.$user, { token: this.$root.authToken });
            await this.$security.reissueToken();
            this.loading = false;
            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Details saved");
        },
        onPhotoCancel() {
            this.$bus.emit(events.APP_BOTTOM_SHEET_OPEN, { show: false });
        },
        async onPhotoDone() {
            await this.$security.reissueToken();
            this.$bus.emit(events.APP_BOTTOM_SHEET_OPEN, { show: false });
        }     
    },
    components: {
        personAvatar
    }
}
</script>