<style scoped>
</style>

<template>
    <div style="display:none;"></div>
</template>

<script>
import constants from "../../../app/app.constants";

export default {
    props: {
        id: {},
        type: {},
        mediasoupDevice: {},
        inputDeviceId: {},
        socket: {}
    },
    data: function () {
        return {
            transport: null,
            producer: null,
            stream: null,
            track: null,
            producerId: null,
            state: "disconnected",
        }
    },
    mounted: async function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        async publish() {
            var result = await this.socket.request("createTransport", {
                forceTcp: false,
                type: "producer",
                routerRtpCapabilities: this.mediasoupDevice.rtpCapabilities
            });
            
            if (result.error) {
                console.log(result.error);
                return;
            }

            this.transport = this.mediasoupDevice.createSendTransport(result);

            this.transport.on("connect", async ({ dtlsParameters }, callback, errback) => {
                this.socket.request("connectTransport", { dtlsParameters, type: "producer", transportId: this.transport.id }).then(callback).catch(errback);
            });

            this.transport.on("produce", async ({ kind, rtpParameters }, callback, errback) => {
                try {
                    var producerId = await this.socket.request("produce", { transportId: this.transport.id, type: this.type, kind, rtpParameters });
                    this.producerId = producerId.id;
                    callback(producerId);
                }
                catch (ex) {
                    console.log(ex);
                    errback(ex);
                }
            });

            this.transport.on("connectionstatechange", (state => {
                this.state = state;
                switch (state) {
                    case "connecting":
                        console.log("producer connecting");
                        break;
                    case "connected":
                        console.log("producer connected");
                        this.transportConnected();
                        break;
                    case "failed":
                        this.transport.close();
                        break;
                }
            }));

            var media = await this.getUserMedia(this.type);
            this.stream = media.stream;
            this.track = media.track;
            var params = { track: this.track };
            // TODO: simulcast

            this.producer = await this.transport.produce(params);
        },
        transportConnected() {
            this.$emit("stream-ready", { stream: this.stream, id: this.id, type: this.type })
        },
        pause() {
            this.producer.pause();
        },
        resume() {
            this.producer.resume();
        },
        async getUserMedia(type) {
            if (type == constants.MEDIA_TYPE.WEBCAM && !this.mediasoupDevice.canProduce("video")) {
                console.error("cannot produce video");
                return;
            }

            var stream;
            var track;

            try {
                if (type === constants.MEDIA_TYPE.WEBCAM) {
                    stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            deviceId: {
                                exact: this.inputDeviceId
                            },
                            width: { exact: 480 }, 
                            height: { exact: 360 }
                        }
                    });
                    track = stream.getVideoTracks()[0];
                }
                else if (type === constants.MEDIA_TYPE.SCREEN) {
                    stream = await navigator.mediaDevices.getDisplayMedia({ 
                        video: true 
                    });
                    track = stream.getVideoTracks()[0];
                }
                else if (type === constants.MEDIA_TYPE.AUDIO) {
                    stream = await navigator.mediaDevices.getUserMedia({
                        audio: {
                            deviceId: {
                                exact: this.inputDeviceId
                            }
                        }
                    });
                    track = stream.getAudioTracks()[0];
                }
            }
            catch (err) {
                console.error("getUserMedia() failed:", err.message);
                throw err;
            }

            return { stream, track };
        },
    },
    components: {
    }
}
</script>