<style scoped>
</style>

<template>
    <div>      
        <whiteboard></whiteboard>
    </div>
</template>

<script>
import whiteboard from "../whiteboard/whiteboard.vue";

export default { 
    data: function () {
        return {
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        test(e) {
            console.log("TEST");
            console.log(e);
        }      
    },
    components: {
        whiteboard
    }
}
</script>