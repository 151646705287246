<style scoped>
</style>

<template>
    <v-navigation-drawer v-model="open" app temporary :right="true">        
        <v-list-item class="pa-2 pl-4 pr-4 pb-1" style="border-bottom: 1px solid #cccccc;">
            <v-btn icon @click="drawerOpen({ show: false })">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-list-item-title class="text-h5" style="text-align:center;">
                Profile
            </v-list-item-title>
            <person-avatar size="32" :avatar="$user.avatar" :name="$user.name"></person-avatar>            
        </v-list-item>
        <v-list>
            <v-list-item v-for="item in menu.items" link color="primary" :to="item.path" :key="item.name">
                <v-list-item-content>
                    <v-list-item-title v-text="item.meta.title" style="text-align: right;"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon v-text="item.meta.icon"></v-icon>
                </v-list-item-icon>
            </v-list-item>
            <template v-if="$user.role == 'admin' && menu.adminItems.length > 0">
                <v-divider class="mt-2"></v-divider>
                <div style="text-align:center;" class="pt-4">
                    <strong>Admin Tools</strong>
                </div>
                <v-list-item v-for="item in menu.adminItems" link color="secondary" :to="item.path" :key="item.name">
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title" style="text-align: right;"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                </v-list-item>                
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import personAvatar from '../../domains/person/person.avatar.vue';
import events from "../app.events";
import tools from "../../util/tools.utils";

export default { 
    data: function () {
        return {
            open: false,
            menu: {
                items: [
                ],
                adminItems: []
            },
        }
    },
    mounted: function () {
        this.menu.items = this.getRoutes("account", false);
        console.log(this.menu.items);        
        this.menu.adminItems = this.getRoutes("account", true);         
    },
    created: function () {
        this.$bus.handle(events.APP_DRAWER_OPEN, this.drawerOpen.bind(this));        
        this.$bus.handle(events.APP_DRAWER_TOGGLE, this.drawerToggle.bind(this));        
    },
    computed: {
    },
    methods: {
        getRoutes(parentName, admin) {
            var routes = this.$router.resolveByNameWithChildren(parentName);

            var filteredRoutes = tools.deepCopy(routes.children);
            if (admin) {
                filteredRoutes = filteredRoutes.filter(r => !r.meta.hide && r.meta.admin);
            }
            else {
                filteredRoutes = filteredRoutes.filter(r => !r.meta.hide && !r.meta.admin);
            }
            return filteredRoutes;
        },
        drawerOpen({ show }) {
            this.open = show;
        },
        drawerToggle() {
            this.open = !this.open;
        },
        getName() {
            if (!this.$user || !this.$user.name) {
                return "?";
            }

            var names = this.$user.name.split(" ");

            var initials = names[1].substring(0, 1);
            if (names.length > 2) {
                initials += names[2].substring(0, 1);
            }
            return initials;
        },
        getImage() {
            return this.$asset.getUrl(this.$user.avatar, "thumbnail");
        }        
    },
    components: { 
        personAvatar
    }
}
</script>