<template>
	<v-dialog persistent v-model="show" scrollable max-width="300px">
		<v-card>
			<v-card-title class="pa-3 pl-4">{{config.title}}</v-card-title>
			<v-divider></v-divider>
			<v-card-text class="pa-4">
				{{config.message}}
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions class="pa-2" style="justify-content:center;">
				<v-btn depressed color="primary" @click="confirm" style="width:100px;" class="mr-4">
					{{ config.confirmTitle ? config.confirmTitle : "Confirm" }}
				</v-btn>
				<v-btn depressed color="accent" @click="cancel" style="width:100px;" class="ml-4">
					{{ config.cancelTitle ? config.cancelTitle : "Cancel" }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default{
	props: {
        value: {
            type: Boolean
        },		
        config: {
            type: Object
        }
	},
    data: function () {
        return {
			show: false
		};
    },
	mounted() {
		this.show = this.value;
	},
		
	watch: {
		value(val) {
			this.show = val;
		}
	},
	methods: {
        confirm() {
			if (this.config && this.config.onConfirm) {
				this.config.onConfirm();
			}
			this.$emit("input", false);
        },
		cancel() {
			if (this.config && this.config.onCancel) {
				this.config.onCancel();
			}
			this.$emit("input", false);
		}
	}
}
</script>