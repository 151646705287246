<style scoped>
</style>

<template>
    <v-container class="fill-height">
        <v-row style="justify-content:center;">
            <v-col class="pa-8" style="max-width:500px;text-align:center;">
                <img src="../../images/emblem.png" style="width:50%;" /><br/><br/><br/>
                <v-text-field solo placeholder="Email Address" v-model="email"></v-text-field>
                <v-btn block color="primary" @click="login" large>Login</v-btn>
                <v-btn style="position:absolute; bottom:10px;right:10px;" @click="openCertWindow">cert fix</v-btn>
            </v-col>
        </v-row>
        <v-dialog persistent v-model="show" scrollable max-width="300px">
            <v-card>
                <v-card-text class="pa-4">
                    <div class="d-flex" style="flex-direction:column;justify-content:center;align-items: center;">
                        <div style="text-align:center;"><strong>Attempting Login</strong><br/><br/></div>
                        <div style="text-align:center;">An email has been sent to<br/><strong>{{ email }}</strong><br/><br/></div>
                        <app-loader></app-loader>
                        <div style="text-align:center;"><br/>Please check your email and<br/>click the button to log into {{ appName }}<br/><br/>Please do not close this page</div>
                    </div>                    
                </v-card-text>
            </v-card>
        </v-dialog>        
    </v-container>
</template>

<script>
import { io } from "socket.io-client";
import config from "../../config";
import appConfig from "../../app/app.config";
import appLoader from "../../app/components/app.loader.vue";

export default { 
    data: function () {
        return {
            email: "",
            success: false,
            show: false,
            appName: appConfig.app.name
        }
    },
    mounted: function () {
        if (this.$root.authToken) {
            //this.$router.push("/listen");
        }
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        openCertWindow() {
            window.open(config.api.uri);
        },
        async login() {
            this.show = true;

            await this.$api.post("login/send", {
                email: this.email,
                deviceId: this.$root.deviceId
            });
            this.awaitCheck();
        },
        awaitCheck() {
            const socket = io(config.api.uri);
            socket.on("connect", () => {
                socket.emit("login.await.check", this.$root.deviceId);
                socket.on("login.await.check.result", (result) => {
                    if (result.successful) {
                        this.$root.authToken = result.token;
                        localStorage.setItem("authToken", this.$root.authToken);
                        this.show = false;
                        this.$router.push("/listen");
                    }
                })
            });
        }
    },
    components: {
        appLoader
    }
}
</script>