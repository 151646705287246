import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
    base: "/",
    routes,
    scrollBehavior(to) {
        if (to.meta.selector) {
            // router 3.x
            return { selector: "#" + to.meta.selector, offset: { x: 0, y: 64 } }
      
            // router 4.x
            //return { el: to.hash }
        }
        
        // otherwise scroll to top
        return { x: 0, y: 0 }

    },
})

var getRoute = function(name, routes) {
    if (!routes) {
        return;
    }

    var result;

    for (var route of routes) {
        if (route.name == name) {
            result = route;
        }

        if (!result) {
            result = getRoute(name, route.children);
        }
    }

    return result;
}

router.resolveByNameWithChildren = function(name) {
    return getRoute(name, routes);
}


router.beforeEach(async (to, from, next) => {
    /*var authToken = localStorage.getItem("authToken");

    if (!authToken && to.path !== "/login/form" && to.path !== "/login/check") {
        return { path: "/login/form" };
    }*/
    next();
});

export default router;