<style scoped>
</style>

<template>
    <v-dialog persistent v-model="show" scrollable max-width="300px">
        <v-card>
            <v-card-text class="pa-4">
                <div class="d-flex" style="flex-direction:column;justify-content:center;align-items: center;">
                    <div style="text-align:center;"><strong>Checking Login Information</strong><br/><br/></div>
                    <app-loader :state="state"></app-loader><br/>
                    <div v-if="state == 'success'" style="text-align:center;">Login was successful, please return to the login webpage to continue to use <strong>{{ appName }}</strong><br/><br/>You may now close this window</div>
                    <div v-else style="text-align:center;">Your login was unsuccessful. This may be due to an expired login link or current service issues. Please try to send a new link via the login screen.<br/><br/>You may now close this window</div>
                </div>                    
            </v-card-text>
        </v-card>
    </v-dialog>        
</template>

<script>
import config from "../../app/app.config";
import appLoader from "../../app/components/app.loader.vue";

export default { 
    data: function () {
        return {
            state: "loading",
            show: true,
            appName: config.app.name
        }
    },
    mounted: function () {
        this.checkToken();
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        async checkToken() {
            this.state = "loading";

            var results = await this.$api.post("login/check", {
                token: this.$route.query.token,
                deviceId: this.$route.query.deviceId
            });

            if (results.data.successful) {
                this.state = "success";
            }
            else {
                this.state = "failure";
            }
        }
    },
    components: {
        appLoader
    }
}
</script>