<style lang="scss">
    @import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,600,700,800,900|Poppins:300,400,500,600,700,700i&display=swap");
</style>

<template>
    <v-app>
        <app-drawer></app-drawer>
        <v-main style="overflow:hidden;position:relative;height:100vh;" id="main">
            <router-view></router-view>
            <app-snackbar></app-snackbar>
            <app-dialog v-model="dialog.show" :config="dialog.config"></app-dialog>
        </v-main>
        <app-bottom-sheet></app-bottom-sheet>
    </v-app>
</template>

<script>
import appDrawer from "../../app/components/app.drawer.vue";
import appBottomSheet from "../../app/components/app.bottom.sheet.vue";
import appDialog from "../../app/components/app.dialog.vue";
import appSnackbar from "../../app/components/app.snackbar.vue";

import events from "../../app/app.events";

export default { 
    data: function () {
        return {
            dialog: {
                show: false,
                config: {
                    onConfirm: null,
                    onCancel: null
                }
            },
        }
    },
    mounted: function () {
    },
    created: function () {
        this.$bus.handle(events.APP_DIALOG_OPEN, this.dialogOpen.bind(this));
    },
    computed: {
    },
    methods: {
        dialogOpen(config) {
            this.$set(this.dialog, "config", config);
            this.dialog.show = true;
        },        
    },
    components: {
    appDrawer,
    appBottomSheet,
    appDialog,
    appSnackbar
}
}
</script>
