import axios from "axios";
import events from "../app/app.events";

export default {
    async get(base, component, path, user, throwError) {
        return this.execute(base, axios.get, component, path, null, user, throwError);
    },
    async delete(base, component, path, user, throwError) {
        return this.execute(base, axios.delete, component, path, null, user, throwError);
    },
    async post(base, component, path, payload, user, throwError, isForm) {
        return this.execute(base, axios.post, component, path, payload, user, throwError, isForm);
    },
    async execute(base, method, component, path, payload, user, throwError, isForm) {
        var result = null;
        var failed = false;
        var error = null;

        var methodParams = [base + path];

        if (payload) {
            methodParams.push(payload);
        }

        var requestConfig = {
            headers: {}
        }

        if (user) {        
            requestConfig.headers["Authorization"] = "Bearer " + user.token;
        }

        if (isForm) {
            requestConfig.headers["Content-Type"] = "multipart/form-data";
        }

        if (user || isForm) {
            methodParams.push(requestConfig);
        }

        var statusCode;
        try {
            result = await method(...methodParams);
        }
        catch (ex) {
            if (ex.response) {
                statusCode = ex.response.status;
            }
            failed = true;
            if (throwError) {
                throw error;
            }
        }

        if (failed) {
            if (statusCode == 403) {
                component.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Login token expired");
                component.$root.
                component.$router.push("/login/form");
            }
            return;
        }

        return result;
    }
}