class Constants {
    // app
    static CREATOR_QUOTES = [
        "Artistically authored",
        "Beautifully brushed",
        "Cheerfully created",
        "Diligently drawn",
        "Expertly etched",
        "Fantastically flourished",
        "Gracefully gifted",
        "Happily handcrafted",
        "Ingenously inked",
        "Joyously jotted",
        "Keenly keyed",
        "Lovingly logged",
        "Magnificently manufactured",
        "Openheartedly output",
        "Proficiently penned",
        "Quickly quilled",
        "Rapidly remitted",
        "Successfully scribbled",
        "Thoughtfully transcribed",
        "Understandingly unleashed",
        "Valourously visualised",
        "Wittily written",
        "Xylographically xeroxed",
        "Youthfully yelled",
        "Zealously zigzagged"
    ];
    static ZOOM_LEVELS = [10, 8.95, 8.13, 7.40, 6.72, 6.11, 5.55, 5.05, 4.59, 4.17, 3.79, 3.45, 3.14, 2.85, 2.59, 2.35, 2.14, 1.95, 1.77, 1.61, 1.46, 1.33, 1.21, 1.1, 1.0, 0.87, 0.79, 0.71, 0.64, 0.57, 0.515, 0.46, 0.42, 0.375, 0.34, 0.30, 0.275, 0.245, 0.22, 0.20, 0.18, 0.16, 0.14, 0.13, 0.12, 0.10];
    static ATTRIBUTE_WHITELIST = [
        "uuid",
        "system",
        "remote",
        "owner",
        "quote"
    ];
    static WHITEBOARD_MODE = {
        TEXT: "text",
        ERASE: "erase",
        SELECT: "select",
        DRAW: "draw"
    }
    static MEDIA_TYPE = {
        WEBCAM: "webcam",
        SCREEN: "screen",
        AUDIO: "audio"
    }
}

export default Constants;