import appLayout from "@/app/app.layout.vue";
import meetLayout from "@/domains/meet/meet.layout.vue";
import accountSettings from "@/domains/account/account.settings.vue";
import friends from "@/domains/account/friends/friends.vue";
import account from "@/domains/account/account.vue";
import loginLayout from "@/domains/login/login.layout.vue";
import loginForm from "@/domains/login/login.form.vue";
import loginCheck from "@/domains/login/login.check.vue";
import comingSoon from "@/app/components/app.coming.soon.vue";
import home from "@/domains/home/home.vue";
import meet from "@/domains/meet/meet.vue";

const routes = [
    { path: "", name: "app", component: appLayout, meta: { title: "App Layout", icon: "mdi-room-service" },
        children: [
            { path: "/", name: "root", component: comingSoon, redirect: "/login/form", meta: { title: "Home", icon: "mdi-home" } },
            { path: "/home", name: "home", component: home, meta: { title: "Home", icon: "mdi-home" } },
            { path: "/account", name: "account", component: account, meta: { title: "Account", icon: "mdi-room-service" },
                children: [
                    { path: "/account/settings", name: "account-settings", component: accountSettings, meta: { title: "Account Settings", icon: "mdi-cog-outline" } },
                    { path: "/account/notifications", name: "account-notifications", component: comingSoon, meta: { title: "Notifications", icon: "mdi-bell-outline" } },
                    { path: "/account/friends", name: "account-friends", component: friends, meta: { title: "Friends", icon: "mdi-account-plus-outline" } },
                    { path: "/account/privacy", name: "account-privacy", component: comingSoon, meta: { title: "Privacy", icon: "mdi-lock-outline" } },
                    { path: "/account/logout", name: "account-logout", component: comingSoon, meta: { title: "Logout", icon: "mdi-logout" } },
                ]
            }    
        ]
    },
    { path: "", name: "meetings", component: meetLayout, meta: { title: "Meetings Layout", icon: "mdi-room-service" },
        children: [
            { path: "/meet", name: "meet", component: meet, meta: { title: "Meet", icon: "mdi-home" } },
        ]
    },    
    { path: "/login", name: "login", component: loginLayout, meta: { title: "Login", icon: "mdi-room-service" }, 
        children: [
            { path: "/login/form", name: "login-form", component: loginForm, meta: { title: "Login Form", icon: "mdi-lightbulb" } },
            { path: "/login/check", name: "login-check", component: loginCheck, meta: { title: "Login Check", icon: "mdi-lightbulb" } },
        ]
    },

]

export default routes;