<style>
    .fab-container {
        position: absolute;
        top: 0;
        right: 0;
    }

</style>

<template>
    <v-layout column class="fab-container">
        <whiteboard-menu-speed-dial v-for="item in items" :key="item.name" :item="item" v-on="getActions(item)"></whiteboard-menu-speed-dial>
    </v-layout>  
</template>

<script>
import whiteboardMenuSpeedDial from "./whiteboard.menu.speed.dial.vue";

export default {
    props: {
        items: []
    }, 
    data: function () {
        return {
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        getActions(item) {
            var actions = {};
            for (var child of item.children) {
                if (child.action) {
                    actions["click:" + child.name] = child.action;
                }
            }
            return actions;
        }
    },
    components: {
        whiteboardMenuSpeedDial
    }
}
</script>