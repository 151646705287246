<style scoped>
</style>

<template>
    <div style="position:relative;">
        <whiteboard-fabric ref="whiteboard" @mouse:down:before="test"></whiteboard-fabric>
        <whiteboard-menu :items="[modes, actions]"></whiteboard-menu>      
    </div>
</template>

<script>
import constants from "../../app/app.constants";

import whiteboardMenu from "./whiteboard.menu.vue";
import whiteboardFabric from "./whiteboard.fabric.vue";

export default { 
    data: function () {
        return {
            whiteboard: null,
            modes: {
                name: "modes",
                icon: "mdi-tools",
                selectable: true,
                children: [
                    { name: constants.WHITEBOARD_MODE.TEXT, icon: "mdi-format-text", action: this.setMode.bind(this)},
                    { name: constants.WHITEBOARD_MODE.ERASE, icon: "mdi-eraser", action: this.setMode.bind(this) },
                    { name: constants.WHITEBOARD_MODE.SELECT, icon: "mdi-select", action: this.setMode.bind(this) },
                    { name: constants.WHITEBOARD_MODE.DRAW, icon: "mdi-draw", action: this.setMode.bind(this) }
                ],
            },
            actions: {
                name: "actions",
                icon: "mdi-file",
                selectable: false,
                children: [
                    { name: "clear", icon: "mdi-delete-forever" },
                    { name: "history", icon: "mdi-history" },
                    { name: "save", icon: "mdi-content-save" }
                ]
            }
        }
    },
    mounted: function () {
        this.whiteboard = this.$refs.whiteboard;
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        setMode(mode) {
            this.whiteboard.setMode(mode.name);
        },
        test(e) {
            console.log("TEST");
            console.log(e);
        }      
    },
    components: {
        whiteboardMenu,
        whiteboardFabric
    }
}
</script>