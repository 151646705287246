class Events {
    // app
    static APP_DRAWER_OPEN = "app-drawer-open";
    static APP_DRAWER_TOGGLE = "app-drawer-toggle";
    static APP_BOTTOM_SHEET_OPEN = "app-bottom-sheet-open";
    static APP_BOTTOM_SHEET_TOGGLE = "app-bottom-sheet-toggle";
    static APP_DIALOG_OPEN = "app-dialog-open";
    static APP_SNACKBAR_MESSAGE = "app-snackbar-message";
    
    // script
    static SCRIPT_READER_START = "script-reader-start";
    static SCRIPT_READER_STOP = "script-reader-stop";
    static SCRIPT_READER_RESET = "script-reader-reset";
}

export default Events;