<style scoped>
    .radial-menu-item {
        transition: transform 1s;
    }
</style>

<template>
    <v-btn fab class="radial-menu-item" style="position:absolute;" :style="style" v-on:click.stop.prevent="click" v-on:mousedown.stop.prevent="">
        <v-icon :size="size / 2">
            {{ icon }}
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    props: {
        radius: { type: Number},
        centerRadius: { type: Number},
        icon: {},
        size: { type: Number },
        angle: { type: Number },
        angleOffset: { type: Number },
        originAngle: { type: Number },
        offset: { type: Number },
        order: { type: Number },
        id: {}
    },
    data: function () {
        return {
            shown: false
        }
    },
    mounted: async function () {
    },
    created: function () {
    },
    computed: {
        center() {
            if (this.centerRadius) {
                return this.centerRadius - (this.size / 2);
            }

            return this.radius - (this.size / 2);
        },
        fullAngle() {
            return this.angleOffset + this.angle;
        },
        fullOriginAngle() {
            return this.angleOffset + this.originAngle;
        },
        style() {
            var rotationAngle = this.fullAngle;
            if (this.originAngle && !this.shown) {
                rotationAngle = this.fullOriginAngle;
                this.$nextTick(() => {
                    this.shown = true;
                });
            }

            return {
                width: this.size + "px", 
                height: this.size + "px", 
                top: this.center + "px", 
                left: this.center + "px",
                zIndex: this.order,
                transform: "rotate(" + rotationAngle + "deg) translateX(" + ((this.radius | 0) + this.offset) + "px) rotate(" + (-rotationAngle) + "deg)"
            }
        }
    },
    methods: {
        click() {
            console.log(this.id);
            this.$emit("click", this.id);
        }
    },
    components: {
    }
}
</script>