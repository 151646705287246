<style scoped>
</style>

<template>
    <div ref="meetContainer" style="position:absolute;width:2000px;height:1500px;border:1px solid red;" :style="{ top: dragPosition.resultTop + 'px', left: this.dragPosition.resultLeft + 'px' }" v-on:mousedown="startDrag">
        <media-device-select ref="media" v-on:enter="enterSpace" v-on:devices-saved="devicesSaved"></media-device-select>

        <media-producer :ref="producer.producerId" v-for="producer in producers" :key="producer.producerId" :id="producer.producerId" :socket="socket" :inputDeviceId="producer.inputDeviceId" :type="producer.type" :mediasoupDevice="device" v-on:stream-ready="producerStreamReady"></media-producer>

        <media-consumer :ref="consumer.consumerId" v-for="consumer in consumers" :key="consumer.consumerId" :id="consumer.consumerId" :outputDeviceId="mediaDevices.audiooutput" :socket="socket" :type="consumer.type" :roomId="consumer.roomId" :attendeeId="consumer.attendeeId" :producerId="consumer.producerId" :mediasoupDevice="device" v-on:stream-ready="consumerStreamReady" v-on:loaded="consumerLoaded" v-on:closed="consumerClosed"></media-consumer>

        <attendee :ref="attendee.id" v-for="attendee in attendees" :key="attendee.id" :id="attendee.id" :packer="packer" :socket="socket" :mediaDevices="mediaDevices" :mediasoupDevice="device" :connectionState="connectionState" :type="attendee.type"></attendee>

        <room :ref="room.id" v-for="(room, i) in rooms" :key="room.id" :id="room.id" :name="room.name" :packer="packer" :offset="i"></room>
    </div>
</template>

<script>
import constants from "../../app/app.constants";
import { v4 as uuidv4 } from "uuid";
import { io } from "socket.io-client";
import { Device } from "mediasoup-client";
import { awaitable } from "../../util/socket.utils";

import mediaProducer from "./media/media.producer.vue";
import mediaConsumer from "./media/media.consumer.vue";
import mediaDeviceSelect from "./media/media.device.select.vue";
import attendee from "./attendee/attendee.vue";

import CirclePacker from "circlepacker";

import room from "./room/room.vue";

import { random } from "circlepacker/src/util.js";

export default { 
    data: function () {
        console.log(window.innerWidth, window.innerHeight);

        return {
            socket: null,
            device: null,
            attendeeId: null,
            producerId: null,
            producers: [],
            consumers: [],
            roomId: "123456",
            packer: null,
            state: {
                microphone: {}
            },
            menuItems: [
                { id: 1, icon: "mdi-video-outline" },
                { id: 2, icon: "mdi-microphone-off" },
                { id: 3, icon: "mdi-monitor-share" }
            ],
            videoMenuItems: [
                { id: 1, icon: "mdi-video" },
                { id: 2, icon: "mdi-video" },
            ],
            screenMenuItems: [
                { id: 1, icon: "mdi-monitor" },
                { id: 2, icon: "mdi-monitor" },
            ],
            mounted: false,
            mediaDevices: {},
            connectionState: "disconnected",
            dragPosition: {
                resultTop: (window.innerHeight - 1500) / 2,
                resultLeft: (window.innerWidth - 2000) / 2
            },
            dragging: false,
            dragHandlers: {
                mouseMove: null,
                mouseUp: null
            },
            rooms: [
                { id: "room1", name: "Cool Place" },
                { id: "room2", name: "Somewhere" },
                { id: "room3", name: "Super Cool" },
                { id: "room4", name: "Banana" },
            ],
            attendees: [
                { id: "user", type: "user" },
                { id: "attendee1", type: "remote" },
                { id: "attendee2", type: "remote" },
                { id: "attendee3", type: "remote" },
                { id: "attendee4", type: "remote" },
                { id: "attendee5", type: "remote" },
                { id: "attendee6", type: "remote" },
                { id: "attendee7", type: "remote" },
                { id: "attendee8", type: "remote" },
            ]
        }
    },
    mounted: async function () {
        this.producers = [];
        window.localStorage.setItem("debug", "mediasoup-client:*");

        const rect = this.$refs.meetContainer.getBoundingClientRect();
        let bounds = { width: rect.width, height: rect.height };
        const target = { x: bounds.width / 2, y: bounds.height / 2 };

        this.packer = new CirclePacker({ bounds, target, circles: [], continuousMode: true, onMove: this.onCircleMove.bind(this), collisionPasses: 3, centeringPasses: 2,  });

        for (var room of this.rooms) {
            var roomComponent = this.$refs[room.id][0];
            var roomCircle = roomComponent.circle;
            roomCircle.position.x = random(roomCircle.radius, bounds.width - roomCircle.radius);
            roomCircle.position.y = random(roomCircle.radius, bounds.height - roomCircle.radius);

            this.packer.addCircle(roomCircle);
        }

    },
    beforeDestroy: async function () {
        await this.leave();
        this.socket.close();
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        onCircleMove(circles) {
            for (var circleId in circles) {
                var circle = circles[circleId];
                var component = this.$refs[circleId][0];
                component.position.x = circle.position.x;
                component.position.y = circle.position.y;
            }
        },
        startDrag(event) {
            var container = this.$refs.meetContainer;

            this.dragPosition.left = container.offsetLeft;
            this.dragPosition.top = container.offsetTop;
            this.dragPosition.x = event.clientX;
            this.dragPosition.y = event.clientY;

            console.log(this.dragPosition);

            document.body.style.cursor = "grabbing";
            document.body.style.userSelect = "none";

            this.dragHandlers.mouseMove = this.mouseMoveHandler.bind(this);
            this.dragHandlers.mouseUp = this.mouseUpHandler.bind(this);

            document.addEventListener("mousemove", this.dragHandlers.mouseMove);
            document.addEventListener("mouseup", this.dragHandlers.mouseUp);
            this.dragging = true;
        },
        mouseMoveHandler(event) {
            if (!this.dragging) {
                return;
            }

            const dx = event.clientX - this.dragPosition.x;
            const dy = event.clientY - this.dragPosition.y;
            
            this.dragPosition.resultTop = this.dragPosition.top - dy;
            this.dragPosition.resultLeft = this.dragPosition.left - dx;
        },
        mouseUpHandler() {
            document.body.style.removeProperty("cursor");
            document.body.style.removeProperty("user-select");
            this.dragging = false;
            document.removeEventListener("mousemove", this.dragHandlers.mouseMove);
            document.removeEventListener("mouseup", this.dragHandlers.mouseUp);
        },
        devicesSaved(mediaDevices) {
            this.mediaDevices = mediaDevices;
        },
        async enterSpace() {
            this.socket = io("wss://collab.thoughtarc.com:4911");

            this.socket.on("connect", () => {
                this.socket.request = awaitable(this.socket);
                this.socket.emit("join", { roomId: this.roomId }, (result) => {
                    this.attendeeId = result;
                    console.log("join", result);
                });
                this.onConnect();
            });

            this.socket.on("new-producer", (payload) => {
                console.log("producer", payload.producerId);
                if (payload.attendeeId != this.attendeeId) {
                    this.consumeProducer(payload);
                }
            });
        },
        async onConnect() {
            for (var attendee of this.attendees) {
                var attendeeComponent = this.$refs[attendee.id][0];
                var attendeeCircle = attendeeComponent.circle;

                const rect = this.$refs.meetContainer.getBoundingClientRect();
                let bounds = { width: rect.width, height: rect.height };     

                attendeeCircle.position.x = random(attendeeCircle.radius, bounds.width - attendeeCircle.radius);
                attendeeCircle.position.y = random(attendeeCircle.radius, bounds.height - attendeeCircle.radius);
                this.packer.addCircle(attendeeCircle);
            }

            this.socket.emit("getRouterRtpCapabilities", {}, async (result) => {
                this.device = await this.loadDevice(result);
                this.connectionState = "connected";
            });
        },
        async loadDevice(routerRtpCapabilities) {
            var device;
            try {
                device = new Device();
                console.log(device);
            } 
            catch (error) {
                console.log(error);
                if (error.name === "UnsupportedError") {
                    console.error("browser not supported");
                }
            }
            await device.load({ routerRtpCapabilities });
            return device;
        },
        consumeProducer(producerDetails) {
            var consumer = {
                consumerId: uuidv4(),
                type: producerDetails.type,
                roomId: producerDetails.roomId,
                attendeeId: producerDetails.attendeeId,
                producerId: producerDetails.producerId,
            }
            this.consumers.push(consumer);
        },
        consumerLoaded(consumerId) {
            var consumerComponent = this.$refs[consumerId][0];
            console.log(consumerComponent);
            consumerComponent.subscribe();
        },
        consumerClosed(details) {
            var consumerId = details.consumerId;
            var consumerIndex = this.consumers.findIndex(consumer => consumer.consumerId == consumerId);
            if (consumerIndex != -1) {
                this.$delete(this.consumers, consumerIndex);
            }
        },
        newAudio() {
            var deviceId = this.$refs.media.audio.input.selected;
            console.log(deviceId);

            if (!deviceId) {
                return;
            }

            var producer = {
                producerId: uuidv4(),
                inputDeviceId: deviceId,
                type: constants.MEDIA_TYPE.AUDIO
            };

            this.producers.push(producer);
        },
        newWebCam() {
            var deviceId = this.$refs.media.video.selected;

            if (!deviceId) {
                return;
            }

            var producer = {
                producerId: uuidv4(),
                inputDeviceId: deviceId,
                type: constants.MEDIA_TYPE.WEBCAM
            };

            this.producers.push(producer);
        },
        newScreenShare() {
            var producer = {
                producerId: uuidv4(),
                type: constants.MEDIA_TYPE.SCREEN
            };

            this.producers.push(producer);
        },

        producerStreamReady(details) {
            if (details.type === constants.MEDIA_TYPE.WEBCAM) {
                this.$refs.localvideo.srcObject = details.stream;            
            }
        },
        consumerStreamReady(details) {
            console.log(details);
        },
        async leave() {
            var result = await this.socket.request("leave", { roomId: this.roomId });
            console.log("leave", result);
        },
    },
    components: {
    mediaProducer,
    mediaConsumer,
    mediaDeviceSelect,
    attendee,
    room
}
}
</script>