<style scoped>
</style>

<template>
    <div @click="magnet" style="position:absolute;background-color:green;border-radius:50%;width:100px;height:100px;line-height:100px;text-align:center;" :style="{ top: (position.y - baseRadius) + 'px', left: (position.x - baseRadius) + 'px' }">
        {{ name }}
    </div>
</template>

<script>
export default {
    props: {
        id: {},
        name: {},
        packer: {},
        offset: {}
    },
    data: function () {
        return {            
            baseRadius: 50,
            position: {
                x: 1000 + this.offset,
                y: 750 + this.offset
            }
        }
    },
    mounted: async function () {
    },
    created: function () {
    },
    computed: {
        circle() {
            return {
                id: this.id,
                radius: this.baseRadius,
                position: this.position
            }
        },
    },
    watch: {
        packer(val) {
            var packer = val;
            this.explode();
            setTimeout(() => {
                packer.pinCircle(this.id);
                packer.setCircleRadius(this.id, this.circle.radius);
            }, 2000);
        }
    },
    methods: {
        explode() {
            console.log("EXPLODE");
            this.packer.setCircleRadius(this.id, this.circle.radius * 5);
        },
        magnet() {
            this.packer.setTarget(this.position);
        }
    },
    components: {
    }
}
</script>