<style lang="scss">
</style>

<template>
    <router-view></router-view>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import tools from "../util/tools.utils";

import "./app.cache";
import "./app.security";

export default { 
    data: function () {
        return {
        }
    },
    mounted: function () {
    },
    created: function () {
        this.loadDeviceId();
        this.loadAuthToken();
        this.setUserByToken(this.$root.authToken);
    },
    computed: {
        token() {
            return this.$root.authToken;
        }
    },
    watch: {
        token(val) {
            this.setUserByToken(val);
        }
    },
    methods: {
        loadDeviceId() {
            var storedDeviceId = localStorage.getItem("deviceId");
            if (storedDeviceId) {
                this.$root.deviceId = storedDeviceId;
            }
            else {
                this.$root.deviceId = uuidv4();
                localStorage.setItem("deviceId", this.deviceId);
            }
        },
        loadAuthToken() {
            var authToken = localStorage.getItem("authToken");
            if (authToken) {
                this.$root.authToken = authToken;
            }
        },
        setUserByToken(val) {
            var payload = tools.jwtDecode(val);
            if (payload) {
                var user = payload.user;
                this.$root.user = user;
            }
        }      
    },
    components: {
    }
}
</script>
