<style>
    .main-button {
        margin: 5px;
    }

    .main-button.more::after {
        content: '\25c4';
        font-size:8px;
        position: absolute;
        left:0px;
        background-color:transparent !important;
    }

    .main-button.more.v-btn--active::after {
        content: '\25ba';
    }

    .v-speed-dial.v-speed-dial--is-active.main {
        background-color:#555555;
        border-radius: 0px 37px 37px 0px;
    }

    .v-speed-dial.v-speed-dial--is-active.main > .v-speed-dial__list {
        background-color: #555555;
        border-radius: 37px 0px 0px 37px;
        padding: 0 5px;
    }

</style>

<template>
    <v-speed-dial ref="menu" :key="componentKey" v-model="local.show" direction="left" class="main">
        <template v-slot:activator>
            <v-btn v-model="local.show" color="black" dark fab large class="main-button more">
                <v-icon>{{ selected.icon }}</v-icon>
            </v-btn>
        </template>
        <v-btn v-for="child in item.children" fab class="child-button" :color="child.name == selected.name ? 'black' : 'white'" :dark="child.name == selected.name" :key="child.name" @click.stop="itemClick(child)">
            <v-icon>{{ child.icon }}</v-icon>
        </v-btn>
    </v-speed-dial>
</template>

<script>
import { ClickOutside } from 'vuetify/lib';

export default {
    props: {
        show: {},
        item: {}
    }, 
    data: function () {
        return {
            selected: this.item,
            local: {
                show: false
            },
            componentKey: 1
        }
    },
    mounted: function () {
        var menu = this.$refs.menu;
        ClickOutside.unbind(menu.$el, null, menu._vnode);
        this.local.show = this.show;
    },
    created: function () {
    },
    computed: {
    },
    watch: {
        show(val) {
            this.local.show = val;
        }
    },
    methods: {
        itemClick(item) {
            if (this.item.selectable) {
                this.selected = item;
            }
            this.$emit("click:" + item.name, item);
        }
    },
    components: {
    }
}
</script>