<style scoped>
    .avatar-container {
        height: 360px;
        width: 360px;
        position: absolute;
        transition: scale 1s;
    }

    .avatar-shadow {
        background-color: #dddddd;
        position: absolute;
        top: 5px;
        height: 350px;
        width: 350px;
        left: 5px;
        border-radius: 50%;
        filter: drop-shadow(0px 0px 15px #666666);
    }

    .avatar-webcam {
        transform: scaleX(-1); /* horizontal flip of video */ 
        pointer-events: none;
        clip-path: circle(178px at center);
        position: absolute;
        z-index: 2;
        margin-left: -60px;
    }

    .avatar-ring {
        background-color: transparent;
        position: absolute;
        top: 0px;
        height: 360px;
        width: 360px;
        left: 0px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 16px solid #f0f0f0;
        z-index: 3;
        box-shadow: inset 0px 0px 10px #666666;
    }

    .avatar-loader {
        position: absolute;
        top: 160px;
        left: 160px;
        z-index: 10;
    }
</style>

<template>
    <div class="avatar-container" :style="{ scale: scale }">
        <div class="avatar-shadow"></div>
        <video ref="videoPlayer" autoplay playsinline class="avatar-webcam" height="360"></video>
        <audio ref="audioPlayer" autoplay></audio>
        <div class="avatar-ring"></div>
        <v-progress-circular v-if="loading" indeterminate color="primary" class="avatar-loader"></v-progress-circular>
    </div>
</template>

<script>
import constants from '../../../app/app.constants';

export default {
    props: {
        scale: { type: Number },
        webcamStream: {},
        audioStream: {},
        outputDeviceId: {}
    },
    data: function () {
        return {
            loading: true
        }
    },
    mounted: async function () {
    },
    created: function () {
    },
    watch: {
        webcamStream(val) {
            this.$refs.videoPlayer.srcObject = val;
            this.loading = false;
        },
        audioStream(val) {
            this.$refs.audioPlayer.srcObject = val;
            if (this.type === constants.MEDIA_TYPE.AUDIO) {
                this.$refs.audioPlayer.setSinkId(this.outputDeviceId);
            } 
        }
    },
    computed: {
    },
    methods: {
    },
    components: {
    }
}
</script>