<style lang="scss">
</style>

<template>
    <canvas ref="whiteboard" width="1920" height="1080" style="border:1px solid red;width:1920px;height:1080px;"></canvas>
</template>

<script>
//import events from "./app.events";
import { fabric } from "fabric";
import "../../util/fabric.utils";
import "../../util/limit";

import constants from "../../app/app.constants";

export default {
    data: function () {
        return {
            canvas: {
                fabric: null,
                objects: [],
                state: {
                    current: {
                        x: 0,
                        y: 0
                    },
                    last: {
                        x: 0,
                        y: 0
                    },
                    dragging: false,
                    scale: {
                        index: 24,
                        value: 1.0
                    }
                }
            },
            mode: null,
            show: {
                pointer: false,
            },
        }
    },
    mounted: function () {
        this.init();
    },
    created: function () {
    },
    computed: {
        drawnObjects: function() {
            return Object.fromEntries(this.canvas.objects.map(o => [o.uuid, o]));
        }
    },
    methods: {
        init() {
            var canvasElement = this.$refs.whiteboard;
            this.canvas.fabric = new fabric.Canvas(canvasElement, {
                isDrawingMode: true,
                targetFindTolerance: 10,
                preserveObjectStacking: true,
                perPixelTargetFind: true,
                fireRightClick: true,  
                stopContextMenu: true,
                enableRetinaScaling: false
            });

            this.canvas.fabric.setWidth(1920); 
            this.canvas.fabric.setHeight(1080);

            var brush = new fabric.PencilBrush(this.canvas.fabric);
            //brush.decimate = 0.8;

            this.canvas.fabric.freeDrawingBrush = brush;
            this.canvas.fabric.freeDrawingBrush.width = 10;
            this.canvas.fabric.freeDrawingBrush.color = "#0000ffff";  

            this.canvas.fabric.on("object:added", this.onObjectAdded.bind(this));
            this.canvas.fabric.on("object:modified", this.onObjectModified.bind(this));
            
            this.canvas.fabric.on("mouse:down:before", this.mouseDownBefore.bind(this));
            this.canvas.fabric.on("mouse:down", this.mouseDown.bind(this));
            this.canvas.fabric.on("mouse:move", this.mouseMove.bind(this).throttle(25));
            this.canvas.fabric.on("mouse:up", this.mouseUp.bind(this));

            fabric.Object.prototype.controls.ownerControl = new fabric.Control({
                x: -0.5,
                y: -0.5,
                offsetY: -5,
                render: this.renderOwner.bind(this)
            });
        },
        setQuote(fabricObject) {
            var r = Math.floor(Math.random() * 1000) % constants.CREATOR_QUOTES.length;
            fabricObject.quote = constants.CREATOR_QUOTES[r];
        },
        setMode(name) {
            this.mode = name;
            this.canvas.fabric.selection = true;
            //fabric.Object.prototype.selectable = true;
            this.canvas.fabric.hoverCursor = "crosshair";
            this.canvas.fabric.defaultCursor = "default";
            
            switch(name) {
                case constants.WHITEBOARD_MODE.DRAW:
                    this.canvas.fabric.isDrawingMode = true;
                    //this.canvas.fabric.freeDrawingCursor = "url('/web/static/images/brush.svg') 0 24, auto";
                    break;
                case constants.WHITEBOARD_MODE.TEXT:
                    this.canvas.fabric.isDrawingMode = false;
                    this.canvas.fabric.defaultCursor = "text";
                    break;
                case constants.WHITEBOARD_MODE.SELECT:
                    this.canvas.fabric.isDrawingMode = false;
                    break;
                case constants.WHITEBOARD_MODE.ERASE:
                    this.canvas.fabric.isDrawingMode = false;
                    this.canvas.fabric.selection = false;
                    //fabric.Object.prototype.selectable = false;
                    //this.canvas.fabric.hoverCursor = "url('/web/static/images/eraser.svg') 4 20, auto";
                    break;
            }
        },
        renderOwner(ctx, left, top, styleOverride, fabricObject) {
            ctx.save();
            ctx.translate(left, top);
            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
            ctx.font = "bold 12px Verdana";
            //var owner = this.users[fabricObject.owner];
            var owner = { firstName: "TO", lastName: "DO" };
            if (owner) {
                ctx.fillStyle = owner.color;
                if (!Object.prototype.hasOwnProperty.call(fabricObject, "quote")) {
                    this.setQuote(fabricObject);
                }
                ctx.fillText(fabricObject.quote + " by " + owner.firstName + " " + owner.lastName, 10, -5);
            }
            ctx.restore();
        },             
        // events
        onObjectAdded(e) {
            this.$emit("object:added", e);
        },
        onObjectModified(e) {
            this.$emit("object:modified", e);
        },        
        mouseDownBefore(e) {
            this.$emit("mouse:down:before", e);

            if (this.canvas.fabric.isDrawingMode) { 
                //clearTimeout(this.replicator.elementTimer);
                return; 
            }

            if (this.mode == constants.WHITEBOARD_MODE.ERASE) {
                var fabricElement = this.canvas.fabric.findTarget(e);
                if (fabricElement) {
                    this.replicator.sendDelete(fabricElement);
                }
                return;
            }
        },
        mouseDown(e) {
            if (e.button == 3) {
                var event = e.e;
                this.canvas.state.dragging = true;
                this.canvas.state.last.x = event.clientX;
                this.canvas.state.last.y = event.clientY;
            }
            else if (!this.canvas.fabric.isDrawingMode && this.mode == constants.WHITEBOARD_MODE.TEXT) {
                var text = new fabric.IText("", { fontFamily: "Verdana", left: e.pointer.x, top: e.pointer.y, fill: this.color.value });
                this.canvas.fabric.add(text).setActiveObject(text);
                text.enterEditing();
                text.on("deselected", function () { 
                    if (this.text.length == 0) {
                        this.canvas.fabric.remove(this);
                    }
                });
                this.mode = constants.WHITEBOARD_MODE.SELECT;
                return;
            }
        },
        mouseMove(e) {
            if (this.canvas.state.dragging == true) {
                var event = e.e;
                this.canvas.state.current.x += event.clientX - this.canvas.state.last.x;
                this.canvas.state.current.y += event.clientY - this.canvas.state.last.y;
                this.canvas.state.last.x = event.clientX;
                this.canvas.state.last.y = event.clientY;
            }

            if (this.show.pointer) {
                this.replicator.sendPointer(e.pointer);
            }
        },
        mouseUp() {
            this.canvas.state.dragging = false;
        },
        keyDown(e) {
            if (e.isComposing || e.keyCode === 229) { return; }

            var fabricElement = this.canvas.fabric.getActiveObject();
            if (!fabricElement) { return; }

            if (e.keyCode == 16 && this.canvas.fabric.snapAngle == 0) {
                fabricElement.snapAngle = 45;
                this.canvas.fabric.snapAngle = 45;
            }
        },
        keyUp(e) {
            if (e.isComposing || e.keyCode === 229) { return; }
            
            var fabricElement = this.canvas.fabric.getActiveObject();
            if (!fabricElement) { return; }

            switch (e.keyCode) {
                case 16: // ALT
                    fabricElement.snapAngle = 0;
                    this.canvas.fabric.snapAngle = 0;
                    break;
                case 46: // DELETE
                    if (fabricElement.type == "activeSelection") {
                        var objects = fabricElement.getObjects();
                        for (var i = 0, length = objects.length; i < length; i++) {
                            var object = objects[i];
                            this.replicator.sendDelete(object);
                        }
                        this.canvas.fabric.discardActiveObject();
                        break;
                    }
                    // do single delete
                    if (!fabricElement.isEditing) {
                        this.replicator.sendDelete(fabricElement);
                    }
                    break;
                case 71: // G (ALT+G = Group)
                    if (!e.altKey) { break; } // + ALT
                    if (fabricElement.type != "activeSelection") { break; }
                    
                    //this.replicator.sendGroup(fabricElement); sync will happen through object modification channel
                    this.canvas.fabric.getActiveObject().toGroup();
                    this.canvas.fabric.requestRenderAll();
                    break;
                case 85: // U (ALT+U = Ungroup)
                    if (!e.altKey) { break; } // + ALT
                    if (fabricElement.type != "group") { break; }

                    var groupObjects = fabricElement.getObjects();
                    for (var j = 0; j < groupObjects.length; j++) {
                        groupObjects[i].preventSync = true;
                    }

                    this.replicator.sendUngroup(fabricElement);
                    fabricElement.toActiveSelection();
                    break;
            }
        },


    },
    components: {
    }
}
</script>
