<style scoped>
</style>

<template>
    <v-row justify="center">
        <v-dialog v-model="show" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    Check your settings
                </v-card-title>
                <v-card-text>
                    <v-container style="text-align:center;">
                        <v-select v-model="audio.input.selected" :items="audio.input.list" item-text="label" item-value="deviceId" label="Microphone"></v-select>
                        <v-select v-model="audio.output.selected" :items="audio.output.list" item-text="label" item-value="deviceId" label="Speaker"></v-select>
                        <v-select v-model="video.selected" :items="video.list" item-text="label" item-value="deviceId" label="Webcam"></v-select>
                        <v-btn @click="enter">Enter Collab Space</v-btn>
                    </v-container>
                </v-card-text>
            </v-card>    
        </v-dialog>
    </v-row>
</template>

<script>
export default { 
    data: function () {
        return {
            show: true,
            audio: {
                input: {
                    list: [],
                    selected: "default"
                },
                output: {
                    list: [],
                    selected: "default"
                }
            },
            video: {
                list: [],
                selected: "default"
            }
        }
    },
    mounted: async function () {
        await navigator.mediaDevices.getUserMedia({audio: true, video: true});   

        if (!navigator.mediaDevices?.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
        } 
        else {
            // List cameras and microphones.
        
            var mediaDevices = await navigator.mediaDevices.enumerateDevices();

            for (var mediaDevice of mediaDevices) {
                switch (mediaDevice.kind) {
                    case "audioinput":
                        this.audio.input.list.push(mediaDevice);
                        break;
                    case "audiooutput":
                        this.audio.output.list.push(mediaDevice);
                        break;
                    case "videoinput":
                        this.video.list.push(mediaDevice);
                }
            }

            this.loadMediaDeviceIds();
        }
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        enter() {
            // TODO: validate
            this.setMediaDeviceIds();
            this.show = false;
            this.$emit("enter");
        },
        loadMediaDeviceIds() {
            var mediaDevices = localStorage.getItem("mediaDevices");
            if (mediaDevices) {
                mediaDevices = JSON.parse(mediaDevices);
            }

            this.audio.input.selected = mediaDevices.audioinput;
            this.audio.output.selected = mediaDevices.audiooutput;
            this.video.selected = mediaDevices.videoinput;

        },
        setMediaDeviceIds() {
            var mediaDevices = {
                audioinput: this.audio.input.selected,
                audiooutput: this.audio.output.selected,
                videoinput: this.video.selected
            }

            this.$emit("devices-saved", mediaDevices);

            localStorage.setItem("mediaDevices", JSON.stringify(mediaDevices));
        }
    },
    components: {
    }
}
</script>