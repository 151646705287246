<style scoped>
</style>

<template>
    <div style="position:absolute;width:360px;height:360px;" :style="{ top: (position.y - baseRadius) + 'px', left: (position.x - baseRadius) + 'px' }">
        <attendee-avatar :scale="scale" :webcam-stream="webcamStream" :audioStream="audioStream" :outputDeviceId="mediaDevices.audiooutput"></attendee-avatar>
        <attendee-menu :radius="circle.radius" :centerRadius="baseRadius" :offset="-8 * scale" :scale="scale" :menu-items="menuItems"></attendee-menu>
        <media-producer v-if="mediaDevices && type === 'user'" ref="webcam" id="webcam" :socket="socket" :inputDeviceId="mediaDevices.videoinput" :type="constants.MEDIA_TYPE.WEBCAM" :mediasoupDevice="mediasoupDevice" v-on:stream-ready="webcamReady"></media-producer>
        <media-producer v-if="mediaDevices && type === 'user'" ref="microphone" id="microphone" :socket="socket" :inputDeviceId="mediaDevices.audioinput" :type="constants.MEDIA_TYPE.AUDIO" :mediasoupDevice="mediasoupDevice" v-on:stream-ready="microphoneReady"></media-producer>
    </div>
</template>

<script>
import constants from "../../../app/app.constants"

import attendeeAvatar from "./attendee.avatar.vue"
import attendeeMenu from "./attendee.menu.vue"

import mediaProducer from "../media/media.producer.vue"

export default {
    props: {
        socket: {},
        mediasoupDevice: {},
        mediaDevices: {},
        connectionState: {},
        type: {},
        id: {},
        packer: {}
    },
    data: function () {
        var menuItems = {};
        if (this.type == "user") {
            menuItems = {
                left: [/*
                    { id: 1, icon: "mdi-video" },
                    { id: 2, icon: "mdi-video" },*/
                ],
                middle: [
                    { id: 1, icon: "mdi-video-outline" },
                    { id: 2, icon: "mdi-microphone-off" },
                    { id: 3, icon: "mdi-monitor-share" }
                ],
                right: [/*
                    { id: 1, icon: "mdi-monitor" },
                    { id: 2, icon: "mdi-monitor-off" },*/
                ]
            }
        }

        var scale = 0.2
        var baseRadius = 180;

        return {
            scale: scale,
            webcamStream: null,
            audioStream: null,
            menuItems,
            
            radius: baseRadius * scale,
            position: {
                x: 0,
                y: 0
            },
            baseRadius: baseRadius,
            constants
        }
    },
    mounted: async function () {
    },
    created: function () {
    },
    computed: {
        circle() {
            return {
                id: this.id,
                radius: this.radius,
                position: this.position
            }
        },        
    },
    watch: {
        scale(val) {
            this.radius = this.baseRadius * val;
            this.packer.setCircleRadius(this.id, this.radius);
        },
        connectionState(val) {
            if (val === "connected" && this.type === "user") {
                this.$nextTick(() => {
                    this.$refs.microphone.publish();
                    this.$refs.webcam.publish();
                });
            }
        }
    },
    methods: {
        webcamReady(details) {
            console.log("webcam")
            this.webcamStream = details.stream;
            this.scale = 0.5;
        },
        microphoneReady(details) {
            if (this.type != "user") {
                this.audioStream = details.stream;
            }
        }        
    },
    components: {
        attendeeAvatar,
        attendeeMenu,
        mediaProducer
    }
}
</script>