<style scoped>
</style>

<template>
    <div style="position:absolute;top:0px;left:0px;">
        <radial-menu :radius="radius" :centerRadius="centerRadius" :order="4" :angle="(baseAngle + ((largeSpacing + (smallSpacing * 1.25)) / scale))" :originAngle="(baseAngle + (largeSpacing / scale))" type="left" :spacing="(smallSpacing / scale)" :size="smallButtonSize" :items="menuItems.left" :offset="offset"></radial-menu>
        <radial-menu :radius="radius" :centerRadius="centerRadius" :order="5" :angle="baseAngle" type="midpoint" :spacing="(largeSpacing / scale)" :size="largeButtonSize" :items="menuItems.middle" :offset="offset"></radial-menu>
        <radial-menu :radius="radius" :centerRadius="centerRadius" :order="4" :angle="(baseAngle - ((largeSpacing + (smallSpacing * 1.25)) / scale))" :originAngle="(baseAngle - (largeSpacing / scale))" type="right" :spacing="(smallSpacing / scale)" :size="smallButtonSize" :items="menuItems.right" :offset="offset"></radial-menu>
    </div>
</template>

<script>
import radialMenu from "../../../components/radial.menu.vue";

export default {
    props: {
        radius: { type: Number },
        centerRadius: { type: Number },
        offset: { type: Number },
        scale: { type: Number },
        menuItems: {}
    },
    data: function () {
        return {
            smallButtonSize: 24,
            largeButtonSize: 36,
            baseAngle: 90,
            largeSpacing: 14,
            smallSpacing: 12
        }
    },
    mounted: async function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
    },
    components: {
        radialMenu
    }
}
</script>