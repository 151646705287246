<style scoped>
    .record-container {
        position: relative;
        display: flex;
        justify-content: center;
    }
</style>

<template>
    <v-container class="record-container pa-4">
        <div style="text-align:center;width:100%;">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field outlined v-model="email" persistent-hint hint="Enter someone's email" :counter="255" :rules="emailRules" label="Email" required></v-text-field>
            </v-form>
            <v-btn depressed class="mt-0" @click="find" color="primary" :loading="loading">Find Friend</v-btn>
            <v-card v-if="foundUser.id" class="pa-4 mb-4 mt-4" elevation="0" style="border:var(--border);"><person-card :item="foundUser" :button="{ title: 'add' }" @button="add"></person-card></v-card> 
            <div v-if="notFound" class="pa-4">
                {{ email }} is not currently a user of <strong>Template</strong>. Invite them?<br/><br/>
                <v-btn small @click="invite" :loading="inviteLoading">Invite</v-btn>
            </div>
            <br/>
            <h2 class="ma-4">My Friends</h2>
            <v-data-iterator :items="friends" :search="search" hide-default-footer :loading="friendsLoading">
                <template v-slot:header>
                    <v-text-field v-model="search" clearable flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" class="mb-5" label="Search"></v-text-field>
                </template>
                <template v-slot:default="props">
                    <v-row>
                        <v-col v-for="item in props.items" :key="item.id" cols="12">
                            <v-card class="pa-4 mb-4 mt-4" elevation="0" style="border:var(--border);"><person-card :item="item"></person-card></v-card> 
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:loading>
                    <div style="display:flex;justify-content:center;" class="pa-8">
                        <app-loader state="loading"></app-loader>
                    </div>
                </template>            
            </v-data-iterator>            
        </div>
    </v-container> 
</template>

<script>
import events from "../../../app/app.events";
import personCard from '../../person/person.card.vue';
import appLoader from "../../../app/components/app.loader.vue";

export default { 
    data() {
        return {
            tab: 0,
            loading: false,
            inviteLoading: false,
            valid: true,
            search: "",
            emailRules: [
                v => !!v || "Email is required",
                v => /.+@.+\..+/.test(v) || "E-mail must be valid",
                v => (v && v.length <= 255) || "Name must be less than 255 characters",
            ],
            friends: [],
            friendsLoading: true,
            email: "",
            foundUser: {},
            notFound: false
        }
    },
    async mounted() {
        this.friends = await this.getFriends();
        this.friendsLoading = false;        
    },
    created() {
    },
    computed: {
    },
    methods: {
        async getFriends() {
            var results = await this.$api.get("user/getFriends", { token: this.$root.authToken });
            
            var friends;
            if (results.data == "") {
                friends = [];
            }
            else {
                friends = results.data.list;
            }

            return Object.values(friends);
        }, 
        async find() {
            this.notFound = false;
            if (!this.$refs.form.validate()) {
                return;
            }
            this.loading = true;
            var user = await this.$api.get("user/getByEmail?email=" + this.email, { token: this.$root.authToken });
            if (user.data == "") {
                this.notFound = true;
                this.loading = false;
                return;
            }

            this.foundUser = user.data;
            this.loading = false;
        },
        async invite() {
            this.inviteLoading = true;
            await this.$api.post("login/invite", { email: this.email }, { token: this.$root.authToken });
            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, this.email + " invited");
            this.inviteLoading = false;
        },
        async add(item) {
            var results = await this.$api.post("user/addFriend", item, { token: this.$root.authToken });
            if (results.data != "") {
                this.friends = Object.values(results.data.list);
            }
            this.foundUser = {};
            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Friend added");
        }
    },
    components: {
        personCard,
        appLoader
    }
}
</script>