<style scoped>
</style>

<template>
    <div>
        {{state}}
        <video v-if="type != 'audio'" ref="player" autoplay playsinline></video>
        <video v-if="type === 'audio'" ref="player" autoplay playsinline></video>
    </div>
</template>

<script>
import constants from "../../../app/app.constants";

export default {
    props: {
        id: {},
        type: {},
        roomId: {},
        attendeeId: {},
        producerId: {},
        outputDeviceId: {},
        mediasoupDevice: {},
        socket: {}
    },
    data: function () {
        return {
            transport: null,
            stream: null,
            state: "disconnected",
        }
    },
    mounted: async function () {
        console.log("loaded");
        this.$emit("loaded", this.id);
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        async subscribe() {
            var result = await this.socket.request("createTransport", {
                forceTcp: false,
                type: "consumer"
            });

            if (result.error) {
                console.log(result.error);
                return;
            }

            var transport = this.mediasoupDevice.createRecvTransport(result);
            
            this.socket.on("close-producer", (payload) => {
                console.log("close producer", payload);
                if (payload.producerId == this.producerId) {
                    transport.close();
                    this.state = "disconnected";
                    this.$emit("closed", { consumerId: this.id });
                }
            });


            transport.on("connect", async ({ dtlsParameters }, callback, errback) => {
                console.log("connected consumer!!!");
                this.socket.request("connectTransport", { dtlsParameters, type: "consumer", transportId: transport.id }).then(callback).catch(errback);
            });
            
            var details;

            transport.on("connectionstatechange", async (state) => {
                this.state = state;
                console.log(this.state);
                switch (state) {
                    case "connecting":
                        console.log("consumer connecting");
                        break;
                    case "connected":
                        console.log("consumer connected");
                        var result = await details;
                        this.transportConnected(result);
                        await this.socket.request("resume", { consumerId: result.consumerId });
                        break;
                    case "failed":
                        transport.close();
                        break;
                }
            });
            
            details = this.consume(transport);
        },
        transportConnected(details) {
            this.stream = details.stream;
            this.$refs.player.srcObject = this.stream;
            if (this.type === constants.MEDIA_TYPE.AUDIO) {
                this.$refs.player.setSinkId(this.outputDeviceId);
            } 
            this.$emit("stream-ready", { stream: this.stream, id: this.id, type: this.type })
        },                        

        async consume(transport) {
            const { rtpCapabilities } = this.mediasoupDevice;
            const data = await this.socket.request("consume", { rtpCapabilities, roomId: this.roomId, attendeeId: this.attendeeId, transportId: transport.id, producerId: this.producerId });
            const {
                producerId,
                id,
                kind,
                rtpParameters,
            } = data;
            
            let codecOptions = {};
            const consumer = await transport.consume({
                id,
                producerId,
                kind,
                rtpParameters,
                codecOptions,
            });
            const stream = new MediaStream();
            stream.addTrack(consumer.track);
            return { consumerId: consumer.id, stream };
        },
    },
    components: {
    }
}
</script>